import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { Link, graphql, navigate } from 'gatsby'
import Layout from '../components/Layout'
import ImageTextRow from '../components/landing/ImageTextRow'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import StandaloneHubspotModal from '../components/marketplace/common/StandaloneHubspotModal'
import { isSignedIn } from '../lib/auth'
import { signIn } from '../redux/actions/user'

export const LoginPageTemplate = ({
  title,
  subtitle,
  image,
  heading,
  hubspotModalProps,
  email,
  password,
  onEmailChange,
  onPasswordChange,
  submitting,
  onSubmit,
  onKeyUp,
  loginError,
}) => (
  <div className="content page-content" style={{ height: '100vh' }}>
    <StandaloneHubspotModal {...hubspotModalProps} hubspotForm="contactUs" />
    <div className="columns" style={{ height: '100vh' }}>
      {/*<div className="column is-one-third no-padding">
        <PreviewCompatibleImage
          imageInfo={{
            image,
            alt: ``,
          }}
          style={{}}
        />
      </div>*/}
      <div className="hero-image is-hidden-touch"></div>

      <div
        className="column is-three-fifths is-full-touch no-padding flex-centered flex-column"
        style={{ backgroundColor: '#1f2e3d', color: 'white' }}
      >
        <PreviewCompatibleImage
          imageInfo={{
            image,
            alt: ``,
          }}
          style={{ width: '30%' }}
        />
        <div className="spacer-10" />
        <span className={`has-text-danger ${!loginError ? 'is-invisible' : ''}`}>
          {loginError || '.'}
        </span>
        <div className="field">
          <label className="label has-text-white-ter">Email</label>
          <div className="control">
            <input
              className="input pulse-input is-medium"
              type="text"
              size={30}
              value={email}
              onChange={onEmailChange}
              onKeyUp={onKeyUp}
              autoFocus
            />
          </div>
        </div>
        <div className="spacer-20" />
        <div className="field">
          <label className="label has-text-white-ter">Password</label>
          <div className="control has-background-transparent">
            <input
              className="input pulse-input is-medium"
              type="password"
              size={30}
              value={password}
              onChange={onPasswordChange}
              onKeyUp={onKeyUp}
            />
          </div>
        </div>
        <div className="spacer-20" />
        <div className="field">
          <div className="control">
            <button
              className="button is-size-5 productButton is-green-with-white"
              onClick={onSubmit}
            >
              Sign In
            </button>
          </div>
        </div>
        <div className="spacer-20" />
        <span onClick={hubspotModalProps.toggleHubspotModal} className="textButton">
          Don't have an account? <span className="has-text-info">Contact Us</span>
        </span>
      </div>
    </div>
  </div>
)

LoginPageTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

class LoginPage extends React.Component {
  state = {
    hubspotOpen: false,
    email: '',
    password: '',
    submitting: false,
    error: null,
  }

  componentDidMount() {
    this.renavigateIfSignedIn()
  }

  componentWillReceiveProps() {
    this.renavigateIfSignedIn()
  }

  renavigateIfSignedIn = () =>
    (isSignedIn()
      ? () =>
          navigate(
            (typeof window !== 'undefined' &&
              queryString.parse(window.location.search).back_to_url) ||
              '/marketplace/tools/university-explorer'
          )
      : () => {})()

  toggleHubspotModal = () => this.setState({ hubspotOpen: !this.state.hubspotOpen })
  onEmailChange = ev => this.setState({ email: ev.target.value })
  onPasswordChange = ev => this.setState({ password: ev.target.value })
  onKeyUp = ev => (ev.keyCode === 13 ? () => this.onSubmit() : () => {})()
  onSubmit = () => this.props.signIn(this.state)

  render() {
    const { data } = this.props
    const { frontmatter } = data.markdownRemark

    return (
      <Layout hideHeaderFooter>
        <LoginPageTemplate
          {...frontmatter}
          hubspotModalProps={{
            open: this.state.hubspotOpen,
            toggleHubspotModal: this.toggleHubspotModal,
          }}
          {...this.state}
          onEmailChange={this.onEmailChange}
          onPasswordChange={this.onPasswordChange}
          onKeyUp={this.onKeyUp}
          onSubmit={this.onSubmit}
          loginError={this.props.loginError}
        />
      </Layout>
    )
  }
}

// const LoginPage = ({ data }) => {
//   const { frontmatter } = data.markdownRemark;
//
//   return (
//     <Layout>
//       <LoginPageTemplate {...frontmatter} />
//     </Layout>
//   );
// };

LoginPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default connect(
  state => ({
    loginError: state.user.loginError,
    user: state.user.user,
  }),
  {
    signIn,
  }
)(LoginPage)

export const aboutPageQuery = graphql`
  query LoginPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
      }
    }
  }
`
